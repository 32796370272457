import React, { Component } from 'react';
import Send from 'mdi-material-ui/Send';
import './SendButton.css';

class SendButton extends Component {
  // constructor(props){
    // super();
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const { color } = this.props;
    
    const style = color ? {backgroundColor: color, color: "#fff"} : null;
    
    return (
      <button className="sendBtn" style={style} type="submit" title="Pošli"><Send /></button>
    );
  }
}

export default SendButton;
