import React, { Component } from 'react';
import LoginVariant from 'mdi-material-ui/LoginVariant';
import Lock from 'mdi-material-ui/Lock';
import UserIcon from '../Lib/UserIcon';
import Users from '../userData.js';
import './Login.css';

class Login extends Component {
  constructor(props){
    super();
    this.state = {
      name: null,
      pass: "",
      initialized: false,
      error: false
    };
    this.input = React.createRef();
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidUpdate(prevProps) {
    this.input.current /*&& this.input.current.focus()*/ && this.input.current.scrollIntoView();
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }
  
  handleClick(name) {
    this.setState({ name: name });
  }
  
  handleChange(event) {
    this.setState({ pass: event.target.value, error: false });
  }
  
  handleSubmit(event) {
    const {name, pass} = this.state;

    this.props.onLogin(name, pass);
    event.preventDefault();
  }

  render() {
    const { name, pass, error } = this.state;
    
    return (
      <div>
        <p className="loginInfo">Prihlásenie Ti otvorí bránu k neskutočnému (duševnému) bohatstvu</p>
        <div className="loginForm">
          {Users.map(user => (
            <div key={user.name} onClick={() => this.handleClick(user.name)}>
              <UserIcon 
                name={user.name} 
                color={ name === user.name || !name ? user.color : user.color.slice(0, -2) + '30' }
              />
              { name === user.name && 
                <form onSubmit={this.handleSubmit}>
                  <input className={error ? "error" : null} type="password" value={pass} ref={this.input} onChange={this.handleChange} /> 
                  <button className={error ? "error" : null} type="submit" >
                    {error 
                      ? <Lock />
                      : <LoginVariant />
                    }
                  </button>
                </form>
              }
              
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Login;
