import React, { Component } from 'react';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';
import ChevronRight from 'mdi-material-ui/ChevronRight';

import './DaySelect.css';

class DaySelect extends Component {
  constructor(props){
    super();
    this.timeout = null;
  }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}
  
  
  handleClick = (event) => {
    const { date, setDate } = this.props;
    
    const change = event.currentTarget.value;
    if(this.timeout === null) {
      this.timeout = setTimeout(() => {
        this.timeout = null;
        date.setDate(date.getDate() + parseInt(change, 10));
        setDate(date);
      }, 200);
    }
  };
  
  handleDblClickBack = (event) => {
    const { setDate, last } = this.props;
    
    clearTimeout(this.timeout);
    this.timeout = null;
    const lastDate = new Date(last);
    setDate(lastDate);
  };
  
  handleDblClickForward = (event) => {
    const { setDate } = this.props;
    
    clearTimeout(this.timeout);
    this.timeout = null;
    const date = new Date();
    setDate(date);
  };
  
  handleChange = (event) => {
    const { setDate } = this.props;
    
    if(event.currentTarget.value) {
      const date = new Date(event.currentTarget.value);
      setDate(date);
    } else {
      setDate(new Date());
    }
  };

  render() {
    const { date } = this.props;
    
    var todaysDate = new Date();
    var today = date.setHours(12,0,0,0) === todaysDate.setHours(12,0,0,0)
    
    return (
      <div className="dayselect">
      <button 
        value={-1} 
        onClick={this.handleClick}
        onDoubleClick={this.handleDblClickBack}>
          <ChevronLeft />
        </button>
        <input
          type="date"
          value={date.toISOString().slice(0,10)}
          min="2010-05-13"
          max={todaysDate.toISOString().slice(0,10)}
          onChange={this.handleChange}
        />
        <button 
          disabled={today} 
          value={1} 
          onClick={this.handleClick}
          onDoubleClick={this.handleDblClickForward}>
            <ChevronRight />
        </button>
      </div>
    );
  }
}

export default DaySelect;
