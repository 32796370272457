import React, { Component } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import './Preview.css';

class Preview extends Component {
  // constructor(props){
    // super();
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const {
      publicId,
      height,
      width,
      setPreview
    } = this.props;
    
    const dpr = window.devicePixelRatio;
    const w = width * dpr;
    const h = height * dpr;
    
    const classes = ["preview"];
    publicId && classes.push("on")
    
    return (
      <div className={classes.join(" ")} onClick={() => {setPreview(null)}}>
        <div>
          { publicId &&
            <Image cloudName="cupsina" publicId={publicId} >
              <Transformation width={w} height={h} crop="limit" />
            </Image>
          }
        </div>
      </div>
    );
  }
}

export default Preview;
