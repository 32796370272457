import React, { Component } from 'react';
import './UserIcon.css';

class UserIcon extends Component {
  // constructor(props){
    // super();
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const { name, color, border, small, title, children } = this.props;
  
    return (
      <span 
        className={["usericon", small ? 'small' : null].join(' ')}
        style={{backgroundColor: color, boxShadow: border ? `0 0 0 1px ${color}` : null}}
        title={title ? title : null}
      >
        { name.slice(0,1) }
        { children }
      </span>
    );
  }
}

export default UserIcon;
