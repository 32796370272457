const Users = [
  { name: "Aďo", color: "#5aaaffff", birthday: [28,7], nameday: [23,3] },
  { name: "Edži", color: "#ff0000ff", birthday: [17,6], nameday: [29,6] },
  { name: "Fero", color: "#e66effff", birthday: [11,8], nameday: [4,10] },
  { name: "Jarík", color: "#ff6414ff", birthday: [27,4], nameday: [27,4] },
  { name: "Mareček", color: "#ff8cbeff", birthday: [15,4], nameday: [25,4] },
  { name: "Pepo", color: "#ffff00ff", birthday: [4,9], nameday: [30,11] },
  { name: "Ranbo", color: "#00ffe6ff", birthday: [24,11], nameday: [29,3] },
  { name: "Rišo", color: "#00ff32ff", birthday: [5,2], nameday: [3,4] },
  { name: "Roman", color: "#deb897ff", birthday: [24,9], nameday: [23,2] }
];

export default Users;
 
