import React, { Component } from 'react';
import Users from '../../userData.js';
import './Waiting.css';

class Waiting extends Component {
  constructor(props){
    super();
    this.state = {};
    
    this.element = React.createRef();
  }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  componentDidUpdate(){
    this.element.current.scrollIntoView(false);
  }

  render() {
    const { name, size } = this.props;
    
    return (
      <div ref={this.element} className={"wait cont" + (size ? ` ${size}` : '')} style={name ? {color: Users.filter(i => i.name === name)[0].color} : null}><span>.</span><span>.</span><span>.</span></div>
    );
  }
}

export default Waiting;
