import React, { Component } from 'react';
import SendButton from '../../Lib/SendButton';
import Users from '../../userData.js'
import './TermSend.css';

class TermSend extends Component {
  constructor(props){
    super();
    this.state = { word: "", description: "" };
    
    this.handleWordChange = this.handleWordChange.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // componentWillMount(){}
//   componentDidMount(){
//     this.input.current.focus();
//   }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}
  
  handleWordChange(event) {
    this.setState({word: event.target.value});
  }
  handleDescChange(event) {
    this.setState({description: event.target.value});
  }
  
  handleSubmit(event) {
    const { word, description } = this.state;
    
    if (word && description) {
      this.props.socket.emit("term", { word: word, description: description });
      this.setState({ word: "", description: "" });
    }
    event.preventDefault();
  }

  render() {
    const { name } = this.props;
    const { word, description } = this.state;
    
    return (
      <div className="termSend">
        <form className="cont" onSubmit={this.handleSubmit}>
          <input type="text" value={word} placeholder="slovo" onChange={this.handleWordChange} />
          <input type="text" value={description} placeholder="význam" onChange={this.handleDescChange} />
          <SendButton color={Users.filter(i => i.name === name)[0].color} />
        </form>
      </div>
    );
  }
}

export default TermSend;
