import React, { Component } from 'react';
import './Badge.css';

class Badge extends Component {
  // constructor(props){
    // super();
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const { side, color } = this.props;
    
    const offset = "-10px";
    let style = {};
    switch(side) {
      case "tl": style = { backgroundColor: color, top: offset, left: offset }; break;
      case "bl": style = { backgroundColor: color, bottom: offset, left: offset }; break;
      case "br": style = { backgroundColor: color, bottom: offset, right: offset }; break;
      case "tr":
      default:
        style = { backgroundColor: color, top: offset, right: offset }; break;
    }
    
    return (
      <div className="badge" style={style} >{this.props.children}</div>
    );
  }
}

export default Badge;
