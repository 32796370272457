import React, { Component } from 'react';

import Linkify from 'react-linkify';
import { Image, Transformation } from 'cloudinary-react';
import Time from './Time';
import KS from '../../img/ks.svg';
import K from '../../img/k.svg';
import J from '../../img/j.svg';
import P from '../../img/p.svg';
import H from '../../img/h.svg';
import Z from '../../img/z.svg';
import UserIcon from '../../Lib/UserIcon';
import Users from '../../userData.js';

import './Message.css';

class Message extends Component {
  constructor(props){
    super();
    this.state = {};
    
    this.element = React.createRef();
  }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
//   componentDidUpdate(){
//     setTimeout(() => this.element.current.scrollIntoView(true), 300);
//   }

  useOurs = text => {
    const { setPreview } = this.props;
    
    let out = [];
    let i = 0;
    if(text.includes("img:")){
      const id = text.slice(4);
      const dpr = window.devicePixelRatio;
      const size = 250 * dpr;
      out.push(
        <Image key={i++} cloudName="cupsina" publicId={id} className="image" onClick={() => {setPreview(id)}} >
          <Transformation width={size} height={size} crop="limit" />
        </Image>
      )
    } else {
      out.push(text);
      
      const regex = /(.*)(:ks|:k|:j|:p|:h|:z)(.*)/gm;
      let pieces = regex.exec(out[0]);
      while(pieces) {
        const regex = /(.*)(:ks|:k|:j|:p|:h|:z)(.*)/gm;
        let icon = ':';
        switch(pieces[2]) {
          case ':ks': icon = <img key={i++} src={KS} alt=":ks" title=":ks" className="icon" /> ; break;
          case ':k': icon = <img key={i++} src={K} alt=":k" title=":k" className="icon" /> ; break;
          case ':j': icon = <img key={i++} src={J} alt=":j" title=":j" className="icon j" />; break;
          case ':p': icon = <img key={i++} src={P} alt=":p" title=":p" className="icon" /> ; break;
          case ':h': icon = <img key={i++} src={H} alt=":h" title=":h" className="icon" /> ; break;
          case ':z': icon = <img key={i++} src={Z} alt=":z" title=":z" className="icon" /> ; break;
          default:
        }
        out.splice(0, 1, pieces[1], icon, pieces[3]);
        pieces = regex.exec(out[0]);
      }
    }
    return out
  }

  render() {
    const { data } = this.props;
    
    const time = new Date(data.time);
    const color = Users.filter(i => i.name === data.name)[0].color;
    const emojiOnly = data.msg.charCodeAt(0) > 55355 && !data.msg.match(/[a-zA-Z0-9]+/g);

    const text = this.useOurs(data.msg);
    
    return (
      <div ref={this.element} 
        className={["message", "cont", data.user].join(" ")}
        style={{color: color}}
      >
        <UserIcon name={data.name} color={color} small={true} />
        <Time>{time.toLocaleTimeString()}</Time> 
        <Linkify properties={{target: '_blank'}}>
          <span className={emojiOnly ? 'emoji' : null}>{text}</span>
        </Linkify>
      </div>
    );
  }
}

export default Message;
