import React, { Component } from 'react';
import Forum from 'mdi-material-ui/Forum';
import PlusBox from 'mdi-material-ui/PlusBox';
import BookOpenPageVariant from 'mdi-material-ui/BookOpenPageVariant';
import BabyBuggy from 'mdi-material-ui/BabyBuggy';
import Plus from 'mdi-material-ui/Plus';
import Badge from '../Lib/Badge';
import './Navigation.css';

class Navigation extends Component {
  constructor(props){
    super();
    this.state = {};
    
    this.pages = [
      { name: "čet", btn: (<Forum />)},
      { name: "nástenka", btn: (<PlusBox />)},
      { name: "slovník", btn: (<BookOpenPageVariant />)},
      { name: "deti", btn: (<BabyBuggy />)}
    ]
  }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  handleClick = (event) => {
    this.props.setPage( event );
  };
  
  render() {
    const { selectedPage, setPage, newMessages } = this.props;

    return (
      <div className="navigation">
        {this.pages.map(page => (
          <div 
            key={page.name}
            className={selectedPage === page.name ? "on" : null}
            onClick={() => {setPage(page.name)}}
          >
            {page.btn}
            <div>
              {page.name}
              {page.name === "čet" && newMessages && <Badge color="#d00"><Plus /></Badge>}
            </div>
          </div>)
        )}
      </div>
    );
  }
}

export default Navigation;
