import React, { Component } from 'react';
import ImagePlus from 'mdi-material-ui/ImagePlus';
import SendButton from '../../Lib/SendButton';
import Users from '../../userData.js'
import './MessageSend.css';

const emojify = text => {
  text = text.replace(/:-?\)( |$)/gm, '🙂');
  text = text.replace(/:-?\)\)+( |$)/gm, '😀');
  text = text.replace(/:-?D+( |$)/gm, '😀');
  text = text.replace(/:-?\(+( |$)/gm, '☹️');
  text = text.replace(/;-?\)( |$)/gm, '😉');
  
  return text;
}

const cloudName = 'cupsina';
const unsignedUploadPreset = 'bj8ds66w';

class MessageSend extends Component {
  constructor(props){
    super();
    this.state = {value: "", uploading: false};
    this.input = React.createRef();
    this.finput = React.createRef();
    
    this.openFile = this.openFile.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // componentWillMount(){}
  componentDidMount(){
    if(this.props.width > 720) {
      this.input.current.focus();
    }
  }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}
  openFile() {
    this.finput.current.click();
  };
  
  handleFiles(event) {
    let file = event.target.files[0]
    this.uploadFile(file); 
  };
  
  async uploadFile(file) {
    const { name } = this.props;
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
    let fd = new FormData();
    fd.append('upload_preset', unsignedUploadPreset);
    fd.append('tags', name);
    fd.append('file', file);
    this.setState({uploading: true});
    this.props.socket.emit("writing");
    
    try {
      let response = await fetch(url, { method: 'POST', body: fd });
      let data = await response.json();
      this.props.socket.emit("message", `img:${data.public_id}`);
      this.setState({uploading: false});
      this.props.socket.emit("idle");
    } catch(err) {
      this.setState({uploading: false});
      this.props.socket.emit("idle");
      alert(err);
    }
  }

  handleChange(event) {
    if(event.target.value) {
      this.props.socket.emit("writing");
    } else {
      this.props.socket.emit("idle");
    }
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    if (this.state.value) {
      this.props.socket.emit("message", emojify(this.state.value));
      this.props.socket.emit("idle");
      this.setState({value: ""});
    }
    event.preventDefault();
  }

  render() {
    const { name } = this.props;
    const { value, uploading } = this.state;
    
    const uclass = uploading ? "uploading" : "";
    return (
      <div className="messageSend">
        <form className="cont" onSubmit={this.handleSubmit}>
          <div className={`fileSend button ${uclass}`} onClick={this.openFile}>
            <input type="file" ref={this.finput} accept="image/*" style={{display:'none'}} onChange={this.handleFiles} />
            <ImagePlus />
          </div>
          <input type="text" value={value} ref={this.input} onChange={this.handleChange} />
          <SendButton color={Users.filter(i => i.name === name)[0].color} />
        </form>
      </div>
    );
  }
}

export default MessageSend;
