import React, { Component } from 'react';
import SendButton from '../../Lib/SendButton';
import UserIcon from '../../Lib/UserIcon';
import Plus from 'mdi-material-ui/Plus';
import Minus from 'mdi-material-ui/Minus';
import Account from 'mdi-material-ui/Account';
import Users from '../../userData.js'
import './PlusSend.css';

class PlusSend extends Component {
  constructor(props){
    super();
    this.state = { 
      plus: true, 
      description: "",  
      to: null,
      showUsers: false
    };
    
    this.handlePlusChange = this.handlePlusChange.bind(this);
    this.handleDescChange = this.handleDescChange.bind(this);
    this.handleToggleUsers = this.handleToggleUsers.bind(this);
    this.handleSetUser = this.handleSetUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // componentWillMount(){}
//   componentDidMount(){
//     this.input.current.focus();
//   }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}
  
  handlePlusChange(event) {
    this.setState({plus: !this.state.plus});
  }
  handleDescChange(event) {
    this.setState({description: event.target.value});
  }
  handleToggleUsers() {
    this.setState({showUsers: !this.state.showUsers});
  }
  handleSetUser(event) {
    this.setState({to: event.currentTarget.dataset.value});
  }
  
  handleSubmit(event) {
    const { plus, description, to } = this.state;
    
    if (description && to) {
      this.props.socket.emit("plus", { sign: plus ? '+' : '-', reason: description, to: to });
      this.setState({ plus: true, description: "", to: null });
    }
    event.preventDefault();
  }

  render() {
    const { name } = this.props;
    const { plus, description, to, showUsers } = this.state;
    
    return (
      <div className="plusSend">
        <form className="cont" onSubmit={this.handleSubmit}>
          <div className="uswitch" onClick={this.handleToggleUsers}>
            <div className="uselect" style={{ display: showUsers ? 'block' : 'none' }} >{
              Users.map((user, index) => <div key={user.name} data-value={index} onClick={this.handleSetUser} ><UserIcon name={user.name} color={user.color} /></div>)
            }</div>
            <button>{
              to
              ? <UserIcon name={Users[to].name} color={Users[to].color} border={true} />
              : <> 
                <Account  />
              </>
            }</button>
          </div>
          <button className="pmswitch" onClick={this.handlePlusChange}>{
            plus 
            ? <Plus />
            : <Minus />
          }</button>
          <input type="text" value={description} placeholder="za čo" onChange={this.handleDescChange} />
          <SendButton color={Users.filter(i => i.name === name)[0].color} />
        </form>
      </div>
    );
  }
}

export default PlusSend;
