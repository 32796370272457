import React, { Component } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Swipeable from 'react-swipeable'
import io from 'socket.io-client';

import Online from './Online';
import Login from './Login';
import Navigation from './Navigation';
import Intro from './Intro';
import Chat from './Chat';
import Board from './Board';
import Dictionary from './Dictionary';
import Children from './Children';
import Waiting from './Lib/Waiting';

import './App.css';

const PAGES = [ "čet", "nástenka", "slovník", "deti" ];

class App extends Component {
  constructor(props){
    super();
    this.state = {
      auth: null,
      connecting: false,
      show: true,
      page: null,
      newMessages: false,
      height: 0,
      width: 0,
      users: [],
      todayChat: false
    };
    
    this.onLogin = this.onLogin.bind(this);
    this.setUsers = this.setUsers.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setToday = this.setToday.bind(this);
    this.onResize = this.onResize.bind(this);
    this.swipedRight = this.swipedRight.bind(this);
    this.swipedLeft = this.swipedLeft.bind(this);
    this.showNotification = this.showNotification.bind(this);
    
    this.socket = null;
  }
  
  onLogin(name, pass) {
    this.setState({auth: null});
    this.socket = io({
      path: '/socket.io',
      reconnectionAttempts: 5,
      reconnectionDelay: 5000,
      randomizationFactor: 0.1
    });
    this.socket.emit('authentication', {meno: name, heslo: pass});
    this.setState({connecting: true});
    this.socket.on('authenticated', () => {
      this.setState({auth: name, connecting: false});
      //this.showNotification('test', 'prihlásenie')
    });
    this.socket.on('unauthorized', (err) => {
      this.setState({auth: false, connecting: false});
      console.log("There was an error with the authentication:", err.message);
    });
    this.socket.on('reconnect', (attempt) => {
      this.setState({auth: null, connecting: true});
      this.socket.emit('authentication', {meno: name, heslo: pass});
    });
    this.socket.on('disconnect', () => {
      this.setState({auth: false});
    });
    
    this.socket.on('newMessage', (name) => {
      if((!this.state.todayChat && this.state.page === "čet") || this.state.page !== "čet") {
        this.setState({newMessages: true});
      }
      if(!this.state.show) {
        document.title = "Problem Solving 4th Gen *";
        this.showNotification("Nová správa", `${name} písal`, "new-message");
      }
    });
    
    window.onunload = () => {
      console.log('disconnecting')
      this.socket.disconnect();
    }
  }
  
  setUsers(users) {
    this.setState({users: users});
  }
  
  setPage(page) {
    if(page === "čet") { 
      this.setState({newMessages: false});
    }
    this.setState({page: page});
  }
  
  setToday(today) {
    if(today) {
      this.setState({todayChat: today, newMessages: false});
    } else {
      this.setState({todayChat: today});
    }
  }
  
  onResize(width, height) {
    this.setState({height: height, width: width});
  }
  
  swipedLeft() {
    const { page, todayChat } = this.state;
    
    const index = PAGES.indexOf(page);
    if(index !== PAGES.length - 1 && (page !== "čet" || todayChat)) {
      this.setPage(PAGES[index + 1])
    }
  }
  swipedRight() {
    const { page } = this.state;
    
    if(page !== "čet") {
      const index = PAGES.indexOf(page);
      this.setPage(PAGES[index - 1])
    }
  }
  
  showNotification(title, body, tag, timeout=5000) {
    if(!this.registration){
      return;
    }
    const options = {
      body: body,//newMessageAuthor + " písal",
      icon: "/android-icon-192x192.png",
      badge: "/badge-icon-72x72.png",
      tag: tag || "notification"
    }
    this.registration.showNotification(title, options)
  }
  
  componentDidMount() {
    window.onfocus = () => {
      this.setState({show: true});
      document.title = "Problem Solving 4th Gen";
      this.registration && this.registration.getNotifications().then(function(notifications) {
        notifications.forEach(notification => {
          notification.close();
        })
      }) 
    };
    window.onblur = () => {
      this.setState({show: false});
    };
    
    Notification.requestPermission( result => {
      if (result === 'granted') {
        navigator.serviceWorker.getRegistrations().then( registrations => {
          this.registration = registrations[0];
        });
      } 
    });
  }
  
  render() {
    const { 
      auth,
      connecting,
      page, 
      height, 
      width,
      users,
      newMessages,
      todayChat
    } = this.state;
    
    if(auth) {
      let content;
      switch(page){
        case "čet": 
          content = 
            <Chat 
              socket={this.socket} 
              users={users} 
              name={auth} 
              height={height} 
              width={width} 
              setToday={this.setToday}
            />
          break;
        case "nástenka": 
          content = <Board socket={this.socket} name={auth} />
          break;
        case "slovník": 
          content = <Dictionary socket={this.socket} name={auth} />
          break;
        case "deti": 
          content = <Children socket={this.socket} />
          break;
        default:
          content = <Intro />;
      }
    
      return (
        <div className="App">
          <Online socket={this.socket} users={users} setUsers={this.setUsers} width={width} />
          <Swipeable className="max" onSwipedRight={this.swipedRight} onSwipedLeft={this.swipedLeft} >
            {content}
          </Swipeable>
          <ReactResizeDetector handleHeight onResize={this.onResize} />
          {
            height > 360 && 
            <Navigation selectedPage={page} setPage={this.setPage} newMessages={newMessages && (!todayChat || page !== "čet")}></Navigation>
          }
        </div>
      );
    } else {
      return (
        <div className="App">
        {
          connecting 
          ? <div className="max"><Waiting size="xxl" /></div>
          : <Login socket={this.socket} onLogin={this.onLogin} error={auth === false} />
            
        }
        </div>
      );
    }
  }
}

export default App;
