import React, { Component } from 'react';
import TermSend from './TermSend';
import './Dictionary.css';

class Dictionary extends Component {
  constructor(props){
    super();
    this.state = {
      words: []
    };
  }

  // componentWillMount(){}
  componentDidMount(){
    const { socket } = this.props;
    
    socket.emit('needData', { page: 'slovnik' });
    
    socket.on('data', (words) => {
      this.setState({ words: words });
    });
  }
  componentWillUnmount(){    
    this.props.socket.off('data');
  }

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const { socket, name } = this.props;
    const { words } = this.state;
    
    return (
      <div className="dictionary max">
        <dl className="scroll">
          <div>
            { 
              words.map(term => (
                <React.Fragment key={term.slovo}>
                  <dt className="cont">{term.slovo}</dt>
                  <dd className="cont">{term.vyznam}</dd>
                </React.Fragment>
              ))
            }
          </div>
        </dl>
        <TermSend socket={socket} name={name} />
      </div>
    );
  }
}

export default Dictionary;
