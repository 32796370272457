import React, { Component } from 'react';
import GenderMale from 'mdi-material-ui/GenderMale';
import GenderFemale from 'mdi-material-ui/GenderFemale';
import Help from 'mdi-material-ui/Help';
import ClockOutline from 'mdi-material-ui/ClockOutline';
import Check from 'mdi-material-ui/Check';
import Users from '../userData.js';
import './Children.css';

class Children extends Component {
  constructor(props){
    super();
    this.state = {
      children: []
    };
    
    this.variants = {
      "m": { color: "#35f", icon: <GenderMale /> },
      "f": { color: "#f33", icon: <GenderFemale /> },
      "n": { color: "#777", icon: <Help /> },
      "0": { color: "#777", icon: <ClockOutline /> },
      "1": { color: "#4f4", icon: <Check /> }
    }
  }

  // componentWillMount(){}
  componentDidMount(){
    const { socket } = this.props;
    
    socket.emit('needData', { page: 'deti' });
    
    socket.on('data', (children) => {
      this.setState({ children: children });
    });
  }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const { children } = this.state;
    
    return (
      <div className="children scroll">
        <div>
          <table className="cont">
            <thead>
              <tr>
                <th>meno</th>
                <th>dátum narodenia<br/>vek</th>
                <th>pohlavie</th>
                <th>status</th>
              </tr>
            </thead>
            <tbody>
            { 
              children.map((child, index) => (
                <tr key={index} style={{ color: Users[child.otec].color }}>
                  <td>{ child.meno }</td>
                  <td>{ new Date(child.datum).toLocaleDateString() }<br/>{ child.vek }</td>
                  <td style={{ color: this.variants[child.pohlavie].color }}>{ this.variants[child.pohlavie].icon }</td>
                  <td style={{ color: this.variants[child.status].color }}>{ this.variants[child.status].icon }</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Children;
