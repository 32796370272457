import React, { Component } from 'react';
import Swipeable from 'react-swipeable'

import DaySelect from './DaySelect';
import Message from './Message';
import Waiting from '../Lib/Waiting';
import MessageSend from './MessageSend';
import Preview from './Preview';

import './Chat.css';

const isToday = date => {
  var todaysDate = new Date();
  return date.setHours(12,0,0,0) === todaysDate.setHours(12,0,0,0);
}

class Chat extends Component {
  constructor(props){
    super();
    
    let date = new Date();
    this.state = {
      date: date,
      messages: [],
      preview: null
    };
    this.content = React.createRef();
    
    this.swipedRight = this.swipedRight.bind(this);
    this.swipedLeft = this.swipedLeft.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setPreview = this.setPreview.bind(this);
  }

  componentDidMount(){
    const { socket, setToday } = this.props;
    const { date } = this.state;
    
    setToday(isToday(date));
    socket.emit('needData', { page: 'cet', day: date });
    
    socket.on('data', (msgs) => {
      this.setState({ messages: msgs });
    });
    socket.on('message', (msg) => {
      console.log('newMessage')
      if(isToday(date)) {
        this.setState({ messages: [...this.state.messages, msg] });
//         this.content.current.scrollIntoView(false)
//         setTimeout(() => {this.content.current.scrollIntoView(false)}, 1500);
      }
    });
  }
  
  componentDidUpdate(){
    const { date } = this.state;
    
    if(isToday(date)) {
      this.content.current.scrollIntoView(false);
    }
  }
  
  componentWillUnmount(){    
    this.props.socket.off('data');
    this.props.socket.off('message');
  }

  swipedLeft(event) {
    const { date } = this.state;
    
    if(!isToday(date)) {
      date.setDate(date.getDate() + 1);
      this.setDate(date);
    }
    event.preventDefault();
  }
  swipedRight() {
    const { date } = this.state;
    
    date.setDate(date.getDate() - 1);
    this.setDate(date);
  }
  
  
  setDate(date) {
    const { socket, setToday } = this.props;
    
    setToday(isToday(date));
    this.setState({ date: date, messages: [] });
    socket.emit('needData', { page: 'cet', day: date });
  }
  
  setPreview(id) {
    this.setState({ preview: id });
  }
  
  render() {
    const { 
      socket, 
      users, 
      name, 
      width, 
      height
    } = this.props;
    
    const { 
      date, 
      messages, 
      preview 
    } = this.state;
    
    const today = isToday(date);
    const last = users.filter(i => i.name === name)[0].lastMessage;
    
    return (
      <div className="chat max" style={ preview ? {zIndex: 3} : null }>
        <DaySelect date={date} setDate={this.setDate} last={last} />
        <Swipeable className="scroll messages" preventDefaultTouchmoveEvent={true} onSwipedRight={this.swipedRight} onSwipedLeft={this.swipedLeft} >
          <div ref={this.content}>
            {messages.map(message => <Message key={message.time} data={message} setPreview={this.setPreview} />)}
            {today 
              ? users.filter(i => i.status === "writing" && i.name !== name).map(user => <Waiting key={user.name} name={user.name} />)
              : null
            }
          </div>
        </Swipeable>
        {today ? <MessageSend socket={socket} name={name} width={width} /> : null}
        <Preview publicId={preview} setPreview={this.setPreview} height={height} width={width} />
      </div>
    );
  }
}

export default Chat;
