import React, { Component } from 'react';
import PlusSend from './PlusSend';
import UserIcon from '../Lib/UserIcon';
import Plus from 'mdi-material-ui/Plus';
import Minus from 'mdi-material-ui/Minus';
import Users from '../userData.js'
import './Board.css';

class Board extends Component {
  constructor(props){
    super();
    this.state = {
      pluses: []
    };
  }

  // componentWillMount(){}
  componentDidMount(){
    const { socket } = this.props;
    
    socket.emit('needData', { page: 'nastenka' });
    
    socket.on('data', (pluses) => {
      this.setState({ pluses: pluses });
    });
  }
  componentWillUnmount(){    
    this.props.socket.off('data');
  }

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const { socket, name } = this.props;
    const { pluses } = this.state;
    let content = [];
    let i = 0;
    
    Users.forEach((user, index) => {
      content.push(<div className="cont komu" key={i++}><UserIcon name={user.name} color={user.color} /></div>);
      const rows = pluses.filter(plus => plus.komu === index+1);
      const rcontent = [];
      rows.forEach(row => {
        const style = row.od > 0 ? {color: Users[(row.od - 1)].color} : null;
        const date = new Date(row.cas);
        rcontent.push(<p className="cont" key={i++} >
          {row.co === '+' 
            ? <Plus style={style} />
            : <Minus style={style} />
          }{row.za} {date.valueOf() > 946681200000 ? <span className="datum">{date.toLocaleDateString()}</span> : null}
        </p>)
      });
      content.push(<div className="cont" key={i++}>{rcontent}</div>);
    })
    
    return (
      <div className="board max">
        <div className="list scroll"><div>{content}</div></div>
        <PlusSend socket={socket} name={name} />
      </div>
    );
  }
}

export default Board;
