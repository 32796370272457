import React, { Component } from 'react';
import Gift from 'mdi-material-ui/Gift';
import UserIcon from '../Lib/UserIcon';
import Badge from '../Lib/Badge';
import Users from '../userData.js';

import './Online.css';

class Online extends Component {
  constructor(props){
    super();
    this.state = {};
    
    const today = new Date();
    this.today = [today.getDate(), today.getMonth() + 1];
  }

  // componentWillMount(){}
  componentDidMount(){
    const { socket, setUsers } = this.props;
    
    socket.on('user', users => {
      setUsers(users)
    });
  }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    const { users, width } = this.props;
    
    return (
      <div className='ulist'>
        {users.map(user => {
          let udata = Users.filter(i => i.name === user.name)[0];
          const last = new Date(user.lastMessage);
          return (
            <UserIcon 
              name={user.name} 
              color={user.status === "offline" 
                ? udata.color.slice(0, -2) + '20' 
                : udata.color
              }
              small={width <= 640 ? true : false} 
              key={user.name}
              title={user.name !== "Mareček" && `naposledy písal ${last.toLocaleDateString()}`}
            >
              {
                (
                  (this.today[0] === udata.birthday[0] && this.today[1] === udata.birthday[1]) 
                  || (this.today[0] === udata.nameday[0] && this.today[1] === udata.nameday[1]) 
                )
                && <Badge color="#d00" side="tr"><Gift /></Badge>
              }
            </UserIcon>
          )}
        )}
      </div>
    );
  }
}

export default Online;
