import React, { Component } from 'react';
import './Intro.css';
import Jarik from '../img/jarik.jpg';
import Pepo from '../img/pepo.jpg';
import Ranbo from '../img/ranbo.jpg';
import Edzi from '../img/edzi.jpg';
import Fero from '../img/fero.jpg';

class Intro extends Component {
  // constructor(props){
    // super();
    // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div className="cont intro">
        <p>Drahý Bože, vďaka za mojich kamarátov. Vďaka ti za zábavu, ktorú spolu zažívame, keď sa hráme počas slnečných dní.</p>
        <div>
          <img src={Jarik} alt="jarik" /> 
          <img src={Pepo} alt="pepo" /> 
          <img src={Ranbo} alt="ranbo" />
          <img src={Edzi} alt="edzi" /> 
          <img src={Fero} alt="fero" />
        </div>
      </div>
    );
  }
}

export default Intro;
